import React, { memo, useState, useEffect, useReducer, useContext, createContext } from 'react'
import axios from 'axios'
import InputText from './InputText'
import Tag from './Tag'

import './layout.scss'

export const context = createContext()
const { Provider } = context

export const initialState = {
  isSpMenu: false
}

export const reducer = (state, actions) => {
  switch (actions.type) {
    case `setIsSpMenu`:
      return { ...state, isSpMenu: actions.payload.isSpMenu }
    default:
      return {}
  }
}

export const Container = memo((props) => {
  const [ state, dispatch ] = useReducer(reducer, initialState)

  return (
    <Provider
      value={{
        state,
        dispatch
      }}
    >
      <div className={ props.className }>
      { props.children }
      </div>
    </Provider>
  )
})

export const Layout = memo(({ className, children }) => {
  return <div className={ className ? `layout ${ className }` : 'layout' }>{ children }</div>
})

export const LayoutMain = memo(({ children }) => {
  const linksAmazonInvite = [
    // 320 x 100
    // kindle
    '<iframe src="https://rcm-fe.amazon-adsystem.com/e/cm?o=9&p=294&l=ur1&category=kindle&banner=12KDPPY6ZCDF1S2XQQ02&f=ifr&linkID=831098d569189af5685c96c12c7b9640&t=mixross-techblog-22&tracking_id=mixross-techblog-22" width="320" height="100" scrolling="no" border="0" marginwidth="0" style="border:none;" frameborder="0"></iframe>',
    // kindle ultimated
    '<iframe src="https://rcm-fe.amazon-adsystem.com/e/cm?o=9&p=294&l=ur1&category=kindleunlimited&banner=1D102G5E4DY0DNM0T382&f=ifr&linkID=2f0f207e6ddacdd6d372d62a47dd2164&t=mixross-techblog-22&tracking_id=mixross-techblog-22" width="320" height="100" scrolling="no" border="0" marginwidth="0" style="border:none;" frameborder="0"></iframe>',
    // amazon prime
    '<iframe src="https://rcm-fe.amazon-adsystem.com/e/cm?o=9&p=294&l=ur1&category=prime&banner=1P5H17MJB4YQ4W7GVCG2&f=ifr&linkID=1bf1ed2cb7cd7020196b6c520ef4468f&t=mixross-techblog-22&tracking_id=mixross-techblog-22" width="320" height="100" scrolling="no" border="0" marginwidth="0" style="border:none;" frameborder="0"></iframe>',
  ]
  const lottery = Math.floor(Math.random() * Math.floor(linksAmazonInvite.length))
  return (
    <div className="layout__main">
      <div className="layout__main__header" dangerouslySetInnerHTML={{__html: linksAmazonInvite[lottery]}} >
      </div>
      { children }
    </div>
  )
})

export const LayoutLeft = memo(() => {
  const linksAmazonInvite = [
    // 120 x 160
    // kindle
    '<iframe src="https://rcm-fe.amazon-adsystem.com/e/cm?o=9&p=11&l=ur1&category=kindle&banner=1RQKWW2KNG787MYGT402&f=ifr&linkID=242e6380203407d290029223622cb472&t=mixross-techblog-22&tracking_id=mixross-techblog-22" width="120" height="600" scrolling="no" border="0" marginwidth="0" style="border:none;" frameborder="0"></iframe>',
    // kindle ultimated
    '<iframe src="https://rcm-fe.amazon-adsystem.com/e/cm?o=9&p=11&l=ur1&category=kindleunlimited&banner=1R5AQJB28PZ1XYJV5H02&f=ifr&linkID=08012b3a0bcda5a33e9b6f31bcdc9a76&t=mixross-techblog-22&tracking_id=mixross-techblog-22" width="120" height="600" scrolling="no" border="0" marginwidth="0" style="border:none;" frameborder="0"></iframe>',
    // amazon prime
    '<iframe src="https://rcm-fe.amazon-adsystem.com/e/cm?o=9&p=11&l=ur1&category=prime&banner=1VZ1EQNXF68GTV1CFR02&f=ifr&linkID=ec2be7fc0056260c1c2cc88249ff4504&t=mixross-techblog-22&tracking_id=mixross-techblog-22" width="120" height="600" scrolling="no" border="0" marginwidth="0" style="border:none;" frameborder="0"></iframe>',
  ]
  const lottery = Math.floor(Math.random() * Math.floor(linksAmazonInvite.length))
  return (
    <div className="layout__left" dangerouslySetInnerHTML={{__html: linksAmazonInvite[lottery]}} >
    </div>
  )
})

export const LayoutAside = memo(() => {
  const [ tags, setTags ] = useState([])
  // const [ state, dispatch ] = useReducer(reducer, initialState)
  const ct = useContext(context)
  const { state } = ct
  // console.log("state", state);

  // タグ一覧
  const fetchTags = async () => {
    let tags = await axios.get('/static/tags.json').then(res => res ? res.data || [] : [])
    tags = tags.map(t => t.fieldValue)

    setTags(tags)
  }

  useEffect(() => {
    fetchTags()

    return () => {}
  }, [])

  const cn = React.useMemo(() => {
    // console.log("@@@", state.isSpMenu);
    return state.isSpMenu ? `layout__aside layout__aside--show` : `layout__aside`
  }, [ state.isSpMenu ])

  return (
    <div className={ cn }>
      <form action="/search" method="get">
        <InputText name="q" placeholder="キーワードを入力" />
      </form>

      <div className="tags">
        { tags.map((tagname, i) => {
            return <Tag key={ i } tagname={ tagname } />
          })
        }
      </div>

      <a className="rss" href="/rss.xml">
        <img src="/img/rss.png" alt="RSS" />
      </a>

    </div>
  )
})
