
class Const {
  get SITE_NAME() { return 'Tech Blog - mixross' }
  get SITE_URL() { return 'https://techblog.mixross.jp' }
  get SITE_IMAGE_FACEBOOK() { return Const.SITE_URL + '/img/site_img_f.png' }
  get SITE_IMAGE_TWITTER() { return Const.SITE_URL + '/img/site_img_t.png' }
  // get AMAZON_TAG() { return 'mixross-techblog-22' }
  get AMAZON_TAG() { return 'willdone-tb-22' }
  get GOOGLE_AD_CLIENT() { return 'ca-pub-xxxxx' }
  get GOOGLE_AD_SLOT() { return 'xxxxx' }
}

export default new Const()
