import React, { memo, useCallback } from 'react'

import './inputText.scss'

const InputText = memo(props => {

  const onChange = useCallback((e) => {
    if (props.onChange) props.onChange(props.name ? { [ props.name ]: e.target.value } : e.target.value)
  }, [])

  return (
    <input
      className="inputText"
      name={ props.name }
      defaultValue={ props.defaultValue }
      onChange={ onChange }
      type="text"
      placeholder={ props.placeholder }
    />
  )
})

export default InputText
